import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScrollComponent} from './scroll.component';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {defineElement} from "lord-icon-element";
import lottie from "lottie-web";
import {ExtendedModule} from "@ngbracket/ngx-layout";

@NgModule({
  declarations: [
    ScrollComponent
  ],
  exports: [
    ScrollComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ExtendedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ScrollModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
