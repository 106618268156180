<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" class="m-t-15 m-l-10">
  <div></div>

  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<form (ngSubmit)="submit()" [formGroup]="form" fxLayout="column" mat-dialog-content>
  <div class="m-b-10 f-12" *ngIf="showServiceInfo">로그인 후 서비스를 이용할 수 있습니다.</div>

  <mat-form-field fxFlex class="m-t-10">
    <mat-label>아이디(이메일)</mat-label>
    <input autocomplete="username" formControlName="username" matInput name="username" placeholder type="email">
  </mat-form-field>

  <mat-form-field>
    <mat-label>비밀번호</mat-label>
    <input autocomplete="password" matInput name="password" placeholder [type]="showPassword ? 'text' : 'password'"
           formControlName="password">
    <mat-icon (click)="toggleShowPassword($event)" class="cursor" matSuffix
              matTooltip="{{showPassword ? '비밀번호 숨김' : '비밀번호 표시'}}"
              matTooltipPosition="above">{{showPassword ? 'visibility' : 'visibility_off'}}
    </mat-icon>
  </mat-form-field>

  <button class="bg-primary p-v-7 hover-primary ff-sc-5" color="primary" mat-raised-button>로 그 인</button>

  <div class="m-t-30 m-b-15 separator f-600">다음 계정으로 로그인</div>
  <div class="p-h-10 p-t-10 p-b-30">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap.xs="15px">
      <div (click)="loginSns('kakao')" class="cursor">
        <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="start center"
             fxLayoutAlign.xs="center center" fxLayoutGap="5px">
          <img alt="카카오 로그인" class="sns-img" src="assets/img/platform/sns-kakao.svg">
          <div class="f-12">카카오</div>
        </div>
      </div>

      <div (click)="loginSns('naver')" class="cursor">
        <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="start center"
             fxLayoutAlign.xs="center center" fxLayoutGap="5px">
          <img alt="네이버 로그인" class="sns-img" src="assets/img/platform/sns-naver.svg">
          <div class="f-12">네이버</div>
        </div>
      </div>

      <div (click)="loginSns('google')" *ngIf="showGoogleLogin" class="cursor">
        <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="start center"
             fxLayoutAlign.xs="center center" fxLayoutGap="5px">
          <img alt="구글 로그인" class="sns-img" src="assets/img/platform/sns-google.svg">
          <div class="f-12">구글</div>
        </div>
      </div>

      <div (click)="loginSns('apple')" class="cursor">
        <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="start center"
             fxLayoutAlign.xs="center center" fxLayoutGap="5px">
          <img alt="애플 로그인" class="sns-img" src="assets/img/platform/sns-apple.svg">
          <div class="f-12">애플</div>
        </div>
      </div>
    </div>
  </div>

  <div class="separator"></div>

  <button class="m-t-30 m-b-20 f-13" mat-dialog-close mat-flat-button routerLink="/account" type="button">
    아이디 찾기 | 비밀번호 찾기
  </button>
  <button class="bg-gray-light p-v-4 hover-primary" mat-dialog-close mat-raised-button routerLink="/join"
          type="button">회원가입
  </button>
</form>
