<div class="p-v-20 p-h-15">
  <div class="p-b-30 lh-1-5" mat-dialog-title>{{title}}</div>

  <mat-dialog-content class="text-pre p-b-20">{{content}}</mat-dialog-content>

  <mat-dialog-actions class="p-t-15" fxLayout="row" fxLayoutGap="15px">
    <button *ngIf="cancelText" [mat-dialog-close]="false" class="hover-dark p-v-4" fxFlex
            mat-stroked-button>{{cancelText}}</button>
    <button [mat-dialog-close]="true" class="bg-primary hover-primary p-v-4" color="primary" fxFlex
            mat-raised-button>{{confirmText}}</button>
  </mat-dialog-actions>
</div>


