import {Component} from '@angular/core';
import {NGXLogger} from "ngx-logger";
import {NativeService} from "../../_service/native.service";
import * as moment from "moment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  showStoreLink = false;
  currentYear = moment().get('year');

  constructor(private log: NGXLogger, private nativeService: NativeService) {
    this.showStoreLink = !this.nativeService.isNative();
  }

  openChat(url: string) {
    this.log.debug('Chat Url', url);
    window.open(url, '1 : 1', 'scrollbars=1, resizable=1, width=486, height=745');
  }

  openFamilySite(url: string) {
    window.open(url, '_blank');
  }
}
