import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MobileFooterSheetComponent} from './mobile-footer-sheet.component';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [
    MobileFooterSheetComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatBottomSheetModule,
    MatListModule,
    MatIconModule
  ]
})
export class MobileFooterSheetModule {
}
