import {NgModule} from '@angular/core';
import {RestClientService} from './rest-client.service';
import {HttpClientModule} from '@angular/common/http';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {ToastService} from './toast.service';

@NgModule({
  imports: [
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule
  ],
  providers: [
    RestClientService,
    ToastService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {autoFocus: false, width: '480px', maxWidth: '90vw', maxHeight: '88vh', hasBackdrop: true}
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {horizontalPosition: 'center', verticalPosition: 'bottom', duration: 3000}
    }
  ]
})
export class SharedModule {
}
