import {Component, Inject} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {UserService} from '../../../domain/user/service/user.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastService} from '../../../../../projects/shared/src/lib/toast.service';
import {SnsLoginService} from '../../../_service/sns-login.service';
import {NativeService} from '../../../_service/native.service';


@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent {
  showServiceInfo = false
  showPassword = false;
  form = new UntypedFormGroup({
    username: new UntypedFormControl(null, [Validators.required]),
    password: new UntypedFormControl(null, [Validators.required])
  });
  showGoogleLogin = false;

  constructor(private log: NGXLogger,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private dialogRef: MatDialogRef<LoginDialogComponent>,
              private toast: ToastService,
              private nativeService: NativeService,
              private userService: UserService,
              private snsService: SnsLoginService) {
    if (this.data.redirect) {
      this.showServiceInfo = true
    }
    
    this.showGoogleLogin = !this.nativeService.isNative() || this.nativeService.isFlutterApp();
  }

  toggleShowPassword(event: MouseEvent) {
    event.stopPropagation();
    this.showPassword = !this.showPassword;
  }

  loginSns(sns: string) {
    this.snsService.openSnsLoginDialog(sns)
  }

  submit() {
    if (this.form.invalid) {
      this.toast.show('아이디와 비밀번호를 입력하세요.');
      return;
    }

    const controls = this.form.controls;
    this.userService.login(controls.username.value, controls.password.value)
      .subscribe((success: boolean) => {
        if (success) {
          this.log.debug("Login Success")
          const principal = {
            service: 'checkteam',
            username: this.form.value.username,
            password: this.form.value.password
          }
          this.log.debug("Call GET FCM KEY");
          this.nativeService.getFcmKey();
          this.log.debug("Call REMEMBER ME");
          this.nativeService.rememberMe(principal);
        }

        this.dialogRef.close(success)
      });
  }
}
