import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {InfoDialogService} from '../shared/dialog/info-dialog/info-dialog.service';
import {UserService} from '../domain/user/service/user.service';
import {NGXLogger} from 'ngx-logger';
import {MatDialog} from '@angular/material/dialog';
import {SessionStorageService} from './session-storage.service';
import {NativeService} from './native.service';

@Injectable({providedIn: 'root'})
export class SnsLoginService {
  private isOpenSnsLoginDialog = false;

  constructor(@Inject('API_HOST') private apiHost: string,
              private log: NGXLogger,
              private router: Router,
              private storage: SessionStorageService,
              private nativeService: NativeService,
              private userService: UserService,
              private matDialog: MatDialog,
              private dialog: InfoDialogService) {
  }

  public getSnsInfo() {
    return this.storage.get('snsInfo');
  }

  public openSnsLoginDialog(sns: string, handler?: any) {
    let messageHandler;
    if (handler) {
      messageHandler = handler;
    } else {
      messageHandler = this.messageHandler;
      this.isOpenSnsLoginDialog = true;
    }

    window.removeEventListener('message', messageHandler, true);

    const openUrl = sns === 'apple' ? `${this.apiHost}/signin/apple` : `${this.apiHost}/oauth2/authorization/${sns}`;
    this.log.debug("Open SNS Login Window")
    window.open(openUrl, '체크팀 SNS 로그인', 'width=819,height=600');

    window.addEventListener('message', messageHandler, false);
  }

  private setSnsInfo(snsInfo: any) {
    this.storage.put('snsInfo', snsInfo);
  }

  public messageHandler = (event: any) => {
    this.log.debug('SNS 로그인 결과', JSON.stringify(event));
    this.log.debug('SNS 로그인 Event Data', JSON.stringify(event.data));

    this.log.debug(`SNS: ${event.data.sns}`)
    this.log.debug(`openDialog: ${this.isOpenSnsLoginDialog}`)

    if (this.isOpenSnsLoginDialog) {
      const result = event.data;
      this.log.debug(`Result: ${JSON.stringify(result)}`);

      if (result.sns === 'apple') {
        if (result.action === 'JOIN') {
          this.setSnsInfo(result)

          const request = {
            username: result.email,
            mobile: null,
            receiveMarketing: false,
            snsInfo: result
          }

          this.userService.createSnsUser(request)
            .subscribe(success => {
              if (success) {
                this.matDialog.closeAll();
                this.router.navigateByUrl('/join/welcome')
              }
            })
        } else if (result.action === 'CONNECT') {
          // LOGIN
          this.userService.loginAppleId(result)
            .subscribe(success => {
              if (success) {
                this.userService.getUser();
                this.matDialog.closeAll();
              }
            })
        }
      } else {
        if (result.join) {
          this.log.debug('SNS Join 완료')
          this.userService.getSessionUser()
            .subscribe(() => {
              this.matDialog.closeAll();
              this.router.navigateByUrl('/join/welcome');
            })
        } else if (result.login) {
          this.log.debug('SNS Login 완료')
          this.nativeService.getFcmKey();
          const routePath = this.router.url === '/login' || this.router.url === '/join' ? '/' : this.router.url;
          this.userService.getSessionUser()
            .subscribe(() => {
              this.matDialog.closeAll();

              this.userService.existsSubscribeHouses()
                .subscribe(exists => {
                  if (exists) {
                    this.router.navigateByUrl('/house/list');
                  } else {
                    this.router.navigateByUrl(routePath);
                  }
                });
            })
        } else if (result.connect) {
          this.userService.getSessionUser().subscribe();
        } else if (result.error !== undefined) {
          if (result.error === 'DUPLICATED') {
            this.dialog.open('SNS 계정 연결 실패', 'SNS 계정 정보(이메일 또는 핸드폰 번호)가 체크팀에서 사용중입니다. 체크팀에 로그인 후 SNS 계정을 연결할 수 있습니다.');
          } else if (result.error === 'ALREADY_USED') {
            this.dialog.open('SNS 계정 연결 실패', '해당 SNS 계정 정보는 체크팀의 다른 계정과 연결되어 있습니다.');
          } else if (result.error === 'NEED_MOBILE') {
            if (result.sns === 'google') {
              this.setSnsInfo(result);
            } else {
              this.setSnsInfo(result.info);
            }
            this.log.debug('SNS 회원가입 시작');
            this.matDialog.closeAll();
            this.router.navigateByUrl('/join/sns');
          } else {
            this.log.error('SNS 연결 실패', 'UNKNOWN_ERROR');
          }
        } else {
          this.log.error('SNS 연결 실패', 'UNKNOWN_RESULT');
        }
      }

      window.removeEventListener('message', this.messageHandler, true);

      this.isOpenSnsLoginDialog = false;
    }
  }
}
