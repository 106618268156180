import {Injectable} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {UserService} from '../domain/user/service/user.service';
import {NGXLogger} from "ngx-logger";

declare let Android: any;
declare let window: any;

@Injectable({providedIn: 'root'})
export class NativeService {
  nativeAndroid = false;
  private native = false;
  private nativeiOS = false;
  isiOS = false;
  private versionCode = 0;

  constructor(private log: NGXLogger, private deviceService: DeviceDetectorService, private userService: UserService) {
    const os = this.deviceService.os;
    if (os === 'iOS') {
      this.isiOS = true;
    }

    if (window.flutter_inappwebview) {
      this.nativeInfo(this.isiOS ? 'ios' : 'android');
    } else {
      this.checkNative();
    }

    window.nativeInfo = (os: any, versionCode: any) => {
      this.log.debug(`nativeInfo: {os: ${os}, versionCode: ${versionCode}}`);
      this.nativeInfo(os);

      this.checkPrincipal();

      this.log.debug("END window.functions");
      this.checkVersion(versionCode);
    }

    window.sendFcmKey = (fcmKey: string) => {
      this.log.debug("sendFcmKey: {}", fcmKey);
      this.sendFcmKey(fcmKey);
    };

    window.autoLogin = (username: string, password: string) => {
      this.log.debug(`autoLogin: ${username} | ${password}`);
      this.autoLogin(username, password);
    }

    this.log.debug("END Native Service Constructor")
  }

  public isNative() {
    this.checkNative();
    if (this.native) {
      return true;
    }

    return window.flutter_inappwebview;
  }

  public isNativeiOS() {
    this.checkNative();
    if (this.nativeiOS) {
      return true;
    }

    return this.native && this.deviceService.os === 'iOS';
  }

  public isNativeAndroid() {
    this.checkNative();
    if (this.nativeAndroid) {
      return true;
    }

    return this.native && this.deviceService.os !== 'iOS';
  }

  public isFlutterApp() {
    return window.flutter_inappwebview;
  }

  public checkPrincipal() {
    if (this.isNative()) {
      this.userService.checkSession().subscribe(isLogin => {
        this.log.debug(`로그인 상태: ${isLogin}`);
        if (!isLogin) {
          if (window.flutter_inappwebview) {
            this.log.debug('Send Flutter checkPrincipal');
            window.flutter_inappwebview.callHandler('checkPrincipal');
          } else if (this.isNativeiOS()) {
            window.webkit.messageHandlers.checkPrincipal.postMessage('')
          }
        }
      })
    }
  }

  public autoLogin(username: string, password: string) {
    if (this.isNative()) {
      this.userService.login(username, password, true)
        .subscribe(success => {
          if (!success) {
            this.forgetMe();
          } else {
            this.getFcmKey();
          }
        });
    }
  }

  public rememberMe(principal: any) {
    if (this.isNative()) {
      if (window.flutter_inappwebview) {
        this.log.debug('Send Flutter rememberMe');
        window.flutter_inappwebview.callHandler('rememberMe', JSON.stringify(principal));
      } else if (this.isNativeiOS()) {
        window.webkit.messageHandlers.rememberMe.postMessage(JSON.stringify(principal));
      }
    }
  }

  public forgetMe() {
    if (this.isNative()) {
      if (window.flutter_inappwebview) {
        this.log.debug('Send Flutter forgetMe');
        window.flutter_inappwebview.callHandler('forgetMe');
      } else if (this.isNativeiOS()) {
        window.webkit.messageHandlers.forgetMe.postMessage('')
      }
    }
  }

  public getFcmKey() {
    if (this.isNative()) {
      if (window.flutter_inappwebview) {
        this.log.debug('Send Flutter getFcmKey');
        window.flutter_inappwebview.callHandler('getFcmKey');
      } else if (this.isNativeAndroid()) {
        Android.getFcmKey()
      } else if (this.isNativeiOS()) {
        window.webkit.messageHandlers.getFcmKey.postMessage('');
      }
    }
  }

  public clearBadge() {
    if (this.isNative()) {
      if (window.flutter_inappwebview) {
        this.log.debug('Send Flutter clearBadge');
        window.flutter_inappwebview.callHandler('clearBadge');
      } else if (this.isNativeiOS()) {
        window.webkit.messageHandlers.clearBadge.postMessage('');
      }
    }
  }

  public openPdf(src: string) {
    if (this.isNative()) {
      if (window.flutter_inappwebview) {
        this.log.debug('Send Flutter openPdf');
        window.flutter_inappwebview.callHandler('openPdf', src);
      } else if (this.isNativeAndroid()) {
        Android.openPdf(src);
      }
    }
  }

  private nativeInfo(os: string) {
    this.native = true;
    if (os.toLowerCase() === 'android') {
      this.nativeAndroid = true;
    } else {
      this.nativeiOS = true;
    }
  }

  private sendFcmKey(token: string) {
    this.log.debug("파이어베이스 토큰 변경 sendFcmKey")
    this.userService.modifyFirebaseKey(token);
  }

  /**
   * @deprecated 새 서버 적용 시 삭제하기
   */
  private checkNative() {
    if (typeof Android !== 'undefined') {
      this.log.debug("Old Android")
      this.native = true;
      this.nativeAndroid = true;
    } else if (window.webkit && window.webkit.messageHandlers && !this.deviceService.userAgent.includes('CriOS')) {
      this.log.debug("Old iOS")
      this.native = true;
      this.nativeiOS = true;
    }
  }

  private checkVersion(currentVersionCode: number) {
    this.versionCode = currentVersionCode;
    const minVersionCode = 11;
    const storeVersionCode = 20;

    if (currentVersionCode >= storeVersionCode) {
      return;
    }

    if (window.flutter_inappwebview) {
      const forceUpdate = currentVersionCode < minVersionCode;
      this.log.debug(`needUpdate forceUpdate: ${forceUpdate}`);
      window.flutter_inappwebview.callHandler('needUpdate', forceUpdate);
    } else if (this.isNativeAndroid()) {
      Android.needUpdate(minVersionCode)
    } else if (this.isNativeiOS()) {
      window.webkit.messageHandlers.needUpdate.postMessage(minVersionCode);
    }
  }
}
