import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {
  title = '';
  content = '';
  cancelText = undefined;
  confirmText = '확인'

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = this.data.title;
    this.content = this.data.content;
    this.cancelText = this.data.cancelButtonText ? this.data.cancelButtonText : undefined;
    this.confirmText = this.data.confirmButtonText ? this.data.confirmButtonText : '확인';
  }
}
