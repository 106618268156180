import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer.component';
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {MatMenuModule} from "@angular/material/menu";
import {MatTooltipModule} from "@angular/material/tooltip";
import {RouterLink} from "@angular/router";

@NgModule({
    declarations: [
        FooterComponent
    ],
    imports: [
        CommonModule,
        ExtendedModule,
        FlexModule,
        MatMenuModule,
        MatTooltipModule,
        RouterLink
    ],
    exports: [FooterComponent]
})
export class FooterModule {
}
