import {Injectable} from '@angular/core';
import {RestClientService} from '../../../../../projects/shared/src/lib/rest-client.service';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {User} from '../model/user';
import {RestResponse} from '../../../../../projects/shared/src/lib/rest-response';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class UserService {
  private user: User | undefined;
  private change = new Subject<User>();

  constructor(private log: NGXLogger, private router: Router, private restClient: RestClientService) {
  }

  public subscribe(): Subject<User> {
    return this.change;
  }

  public checkSession(): Observable<boolean> {
    if (this.user !== undefined) {
      return new Observable<boolean>(observer => observer.next(true));
    }

    return this.restClient.get('/session')
      .pipe(map(result => {
        return result.data;
      }))
  }

  public login(username: string, password: string, reload?: boolean): Observable<boolean> {
    return this.restClient.post('/signin', {username, password})
      .pipe(map((response: RestResponse) => {
        if (response.success) {
          this.log.debug("Login Success");
          this.user = response.data;
          this.change.next(this.user);

          if (this.user?.expired) {
            this.log.debug('비밀번호 변경 3개월 초과')
            this.router.navigateByUrl('/account/expired');
          } else {
            this.existsSubscribeHouses()
              .subscribe(exists => {
                if (exists) {
                  this.router.navigateByUrl('/house/list');
                }
              });
          }
        }

        return response.success;
      }));
  }

  public existsSubscribeHouses(): Observable<boolean> {
    return this.restClient.get('/users/houses/exists')
      .pipe(map((response => response.data)));
  }

  public loginAppleId(request: any): Observable<boolean> {
    return this.restClient.post('/signin/apple', request)
      .pipe(map((response: RestResponse) => {
        if (response.success) {
          this.user = response.data;
          this.change.next(this.user);
        }

        return response.success;
      }));
  }

  public logout(): void {
    this.restClient.get('/signout')
      .subscribe(result => {
        this.log.debug(result);
        this.user = undefined;
        this.change.next(this.user);
        this.router.navigateByUrl('/');
      })
  }

  public clearSession(): void {
    this.user = undefined;
    this.change.next(this.user);
    this.router.navigateByUrl('/');
  }

  public getSessionUser(): Observable<User> {
    if (this.user !== undefined) {
      return new Observable<User>(observer => observer.next(this.user))
    }

    return this.restClient.get('/users')
      .pipe(map(response => {
        this.user = response.data;
        this.change.next(this.user);
        return response.data;
      }))
  }

  public getUser(): Observable<any> {
    return this.restClient.get('/users')
      .pipe(map(response => {
        return response.data;
      }));
  }

  public getUserSns(): Observable<any> {
    return this.restClient.get('/users/sns')
      .pipe(map(response => {
        return response.data;
      }));
  }

  public createUser(request: any): Observable<boolean> {
    this.log.debug('사용자 생성', request);
    return this.restClient.post('/users', request)
      .pipe(map((response: RestResponse) => {
        this.user = response.data;
        this.change.next(this.user);
        return true;
      }));
  }

  public createSnsUser(request: any): Observable<boolean> {
    this.log.debug('SNS 사용자 생성', request);

    return this.restClient.post('/users/sns', request)
      .pipe(map((response: RestResponse) => {
        this.user = response.data;
        this.change.next(this.user);
        return true;
      }));
  }

  public resetPassword(request: any): Observable<boolean> {
    return this.restClient.post('/reset/password', request)
      .pipe(map(response => {
        return response.success;
      }))
  }

  public modifyFirebaseKey(firebaseKey: string): void {
    this.restClient.put('/users/fcm', firebaseKey)
      .subscribe()
  }

  public modifyMobile(mobile: string): Observable<any> {
    return this.restClient.put('/users/mobile', {mobile})
      .pipe(map(response => {
        return response.success;
      }));
  }

  public modifyPassword(request: any): Observable<any> {
    return this.restClient.put('/users/password', request)
      .pipe(map(response => {
        return response.success;
      }));
  }

  public modifyReceiveMarketing(agree: boolean): Observable<any> {
    return this.restClient.put('/users/marketing', agree)
      .pipe(map(response => {
        return response.success;
      }));
  }

  public postponeChangePassword(): Observable<any> {
    return this.restClient.put('/users/postpone')
      .pipe(map(response => {
        return response.success;
      }));
  }

  public leave(request: any): Observable<any> {
    return this.restClient.delete('/users', request)
      .pipe(map(response => {
        this.user = undefined;
        this.change.next(this.user);

        return response.success;
      }));
  }

  public unlinkSns(sns: string): Observable<any> {
    return this.restClient.delete('/users/sns', {sns: sns})
      .pipe(map(response => {
        return response.success;
      }));
  }
}
