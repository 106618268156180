import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {UserService} from '../domain/user/service/user.service';
import {map} from 'rxjs/operators';
import {LoginDialogService} from '../shared/dialog/login-dialog/login-dialog.service';

export const UserGuard: CanActivateFn = (route, state) => {
  const log = inject(NGXLogger);
  const userService = inject(UserService);
  const loginDialogService = inject(LoginDialogService)

  log.debug('사용자 가드 확인 시작');

  return userService.checkSession()
    .pipe(map(result => {
      log.debug('세션 결과 확인', result);
      if (!result) {
        log.debug('비로그인 사용자 로그인 다이얼로그 열기');
        loginDialogService.openLoginDialog(route.routeConfig?.path, route.queryParams)
      }

      return result;
    }));
}
