import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfoDialogComponent} from './info-dialog.component';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    InfoDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class InfoDialogModule {
}
