import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LoginDialogComponent} from './login-dialog.component';
import {NGXLogger} from 'ngx-logger';
import {Params, Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class LoginDialogService {

  constructor(private log: NGXLogger, private router: Router, private matDialog: MatDialog) {
  }

  openLoginDialog(redirectPath?: string, queryParams?: Params): void {
    this.matDialog.open(LoginDialogComponent, {disableClose: true, data: {redirect: redirectPath}})
      .afterClosed()
      .subscribe((success: boolean) => {
        this.log.debug('로그인 결과 : ', success);
        if (success) {
          if (redirectPath) {
            this.router.navigate([`/${redirectPath}`], {queryParams: queryParams});
          } else {
            const currentUrl = this.router.url;
            if (currentUrl.indexOf('/join') === 0 || currentUrl.indexOf('/account') === 0) {
              this.router.navigateByUrl('/');
            }
          }
        }
      });
  }
}
