<div *ngIf="notice" ngClass.gt-xs="bg-gray-light" ngClass.xs="bg-white p-b-15">
  <section class="banner-xs p-b-2">
    <div class="cursor" ngClass.xs="p-l-20 p-r-5">
      <div (click)="openNotice()" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="7px">
        <div class="text-badge-warn-fill">공지</div>
        <div class="f-14 lh-1-7 f-600" ngClass.gt-xs="f-14 f-600" ngClass.xs="lh-1-8">
          {{notice.title}}
          <span ngClass.xs="c-gray" ngClass.gt-xs="p-l-10" *ngIf="isIrosNotice"><br *ngIf="isMobile"/>기간ㅣ{{notice.displayMainStartAt | date: 'yyyy.MM.dd. HH : mm'}} - {{notice.displayMainEndAt | date: 'MM.dd. HH : mm'}}</span>
        </div>

      </div>
    </div>
  </section>
</div>

<div ngClass.gt-xs="bg-gray-light" ngClass.xs="bg-gray-ea">
  <section class="banner-xs cursor" routerLink="/info/my-house">
    <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="start center">
      <div fxFlex fxFlex.gt-xs="55">
        <div ngClass.gt-xs="p-t-100 p-b-140" ngClass.xs="p-h-22">
          <div>
            <div ngClass.gt-xs="mat-subtitle-1 ff-sc-6 m-b-15" ngClass.xs="mat-subtitle-2 ff-sc-6 p-t-25 m-b-5">
              부동산 등기변경 알림으로
            </div>
            <div ngClass.gt-xs="mat-headline-5 ff-sc-6 f-600 lh-1-6"
                 ngClass.xs="mat-subtitle-1 ff-sc-6 lh-1-5 f-600 m-b-5">
              위험 등기를 <span ngClass.gt-xs="underline accent"> 빠르고 간편하게 확인하세요 </span>.
            </div>

            <div fxLayout="column" ngClass.gt-xs="p-b-20">
              <div fxLayout.xs="row wrap" fxLayoutAlign="start center"
                   fxLayoutGap.gt-xs="5px" fxLayoutGap.xs="3px">
                <div ngClass.gt-xs="gray-bg-chip-lg" ngClass.xs="gray-bg-chip-xs m-b-7">임차인</div>
                <div ngClass.gt-xs="gray-bg-chip-lg" ngClass.xs="gray-bg-chip-xs m-b-7">채권자</div>
                <div ngClass.gt-xs="gray-bg-chip-lg" ngClass.xs="gray-bg-chip-xs m-b-7">부동산 계약</div>
                <div ngClass.gt-xs="gray-bg-chip-lg" ngClass.xs="gray-bg-chip-xs m-b-7">부동산 담보</div>
                <div ngClass.gt-xs="gray-bg-chip-lg" ngClass.xs="gray-bg-chip-xs m-b-7">가맹점 담보</div>
                <div ngClass.gt-xs="gray-bg-chip-lg" ngClass.xs="gray-bg-chip-xs m-b-7">소유자</div>
                <div ngClass.gt-xs="gray-bg-chip-lg" ngClass.xs="gray-bg-chip-xs m-b-7">가족</div>

              </div>
            </div>

            <div fxHide fxLayout="row" fxLayoutAlign.gt-xs="start center" fxLayoutAlign.xs="start start" fxShow.gt-xs
                 fxLayoutGap="5px"
                 ngClass.gt-xs="p-t-5" ngClass.xs="p-t-10">
              <lord-icon
                colors="primary:#333333"
                delay="2000"
                fxHide
                fxShow.gt-xs
                src="https://cdn.lordicon.com/psnhyobz.json"
                style="width:30px; height:30px"
                trigger="loop">
              </lord-icon>

              <lord-icon
                colors="primary:#333333"
                fxHide.gt-xs
                fxShow
                src="https://cdn.lordicon.com/psnhyobz.json"
                style="width:25px;height:25px"
                trigger="hover">
              </lord-icon>

              <div ngClass.gt-xs="mat-subtitle-2 m-b-0 f-600 ff-sc-6 p-t-3"
                   ngClass.xs="mat-body-2 ff-sc-6 f-600 p-t-3" style="z-index: 2;">
                등기변경이 확인되면 알림으로 안내됩니다.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex fxFlex.gt-xs="42" fxFlexOffset.gt-xs="3" fxHide fxShow.gt-xs
           ngClass.gt-xs="p-v-20 m-t-m100 text-right">
        <img alt="등기알림등본열람" class="w-100" src="assets/img/service/info/main-top-mobile-push.png">
      </div>

<!--      <div class="text-right m-t-m40 img-gray" fxHide.gt-xs fxShow>-->
<!--        <img alt="등기알림등본열람" ngClass.gt-xs="w-100" ngClass.xs="w-80"-->
<!--             src="assets/img/service/info/info-top-nonetext-01.png">-->
<!--      </div>-->
      <div class="text-right" fxHide.gt-xs fxShow>
<!--        <img alt="등기알림등본열람" ngClass.gt-xs="w-100" ngClass.xs="w-80 m-t-m80"-->
<!--             src="assets/img/service/info/mobile-point.png">-->

        <img alt="등기알림등본열람" ngClass.gt-xs="w-100" ngClass.xs="w-80 m-t-m20"
             src="assets/img/service/info/info-top-nonetext-01.png">

      </div>
    </div>
  </section>
</div>


<div ngClass.xs="bg-gray-ea">
  <section class="banner p-v-10">
    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="20px"
         ngClass.gt-xs="p-v-40 m-t-m100" ngClass.xs="p-v-15 p-h-15 m-t-m230">

      <div fxFlex="100" fxFlex.gt-xs="40" id="myreal">
        <div class="brd-15 cursor"
             ngClass.gt-xs="bg-white shadow-box-xs hover-gray-shadow p-t-10 p-b-20"
             ngClass.xs="bg-white-blur shadow-box p-h-17 p-t-30 p-b-20 m-b-15"
             routerLink="/house/list">
          <div fxLayout="column">
            <div ngClass.gt-xs="p-h-10">
              <lord-icon
                colors="primary:#dadada,secondary:#0FA564"
                fxHide
                fxShow.gt-xs
                src="https://cdn.lordicon.com/xowpdgxz.json"
                style="width:130px;height:130px"
                target="#myreal"
                trigger="hover">
              </lord-icon>
            </div>

            <div ngClass.gt-xs="m-t-m5 p-h-30">
              <div ngClass.xs="p-b-10">
                <div ngClass.gt-xs="mat-subtitle-2 m-b-3 ff-sc-6"
                     ngClass.xs="mat-subtitle-2 p-t-5 m-b-3 ff-sc-7 f-700">
                  마이 부동산
                </div>
                <div ngClass.gt-xs="mat-body-1 f-600" ngClass.xs="mat-body-2 f-700">
                  <span ngClass.xs="underline accent">등기 변경정보 &middot; 부동산등본 열람 &middot; 통계 &middot; 변경등기정보 다운로드 </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div fxFlex fxFlex.gt-xs="60" ngClass.xs="m-t-20" style="z-index: 2">
        <div fxLayout.gt-xs="row" fxLayout.xs="row wrap" fxLayoutAlign.xs="space-between center"
             fxLayoutGap.gt-xs="12px" ngClass.gt-xs="m-t-50">
          <div [queryParams]="{type: 'MY_HOUSE'}" class="cursor w-100 brd-15 bg-white" fxFlex.gt-xs="33" fxFlex.xs="48"
               id="myreal-re"
               ngClass.gt-xs="p-b-20 p-t-40 m-t-m50 p-h-15  shadow-box-xs hover-gray-shadow"
               ngClass.xs="p-h-5 p-t-8 p-b-11 m-b-15 shadow-box bg-accent"
               routerLink="/house/register">
            <div class="text-center" fxLayout="column" fxLayoutAlign="center center">

              <lord-icon colors="primary:#0FA564,secondary:#f6f7f7,tertiary:#0FA564"
                         fxHide
                         fxShow.gt-xs
                         src="https://cdn.lordicon.com/kvlmvlad.json"
                         style="width:90px;height:90px"
                         target="#myreal-re"
                         trigger="hover">
              </lord-icon>
              <lord-icon colors="primary:#0FA564,secondary:#f6f7f7,tertiary:#0FA564"
                         src="https://cdn.lordicon.com/kvlmvlad.json"
                         fxHide
                         style="width:70px; height:70px"
                         target="#myreal-re"
                         trigger="hover">
              </lord-icon>

<!--              <lord-icon colors="primary:#0FA564,secondary:#ad5e99"-->
<!--                         src="https://cdn.lordicon.com/fsfgtaly.json"-->
<!--                         style="width:70px; height:70px"-->
<!--                         target="#myreal-re"-->
<!--                         trigger="hover"-->
<!--                         fxHide.gt-xs-->
<!--                         fxShow>-->
<!--              </lord-icon>-->

<!--              <lord-icon colors="primary:#0fa564,secondary:#ffffff,tertiary:#0fa564,quaternary:#ffffff"-->
<!--                         src="https://cdn.lordicon.com/ulpqzbwq.json"-->
<!--                         style="width:70px; height:70px"-->
<!--                         target="#myreal-re"-->
<!--                         trigger="hover"-->
<!--                         fxHide.gt-xs-->
<!--                         fxShow>-->
<!--              </lord-icon>-->
              <lord-icon colors="primary:#333333,secondary:#333333"
                         delay="10000"
                         fxHide.gt-xs
                         fxShow
                         src="https://cdn.lordicon.com/jcggqsgk.json"
                         style="width:75px;height:75px;"
                         trigger="loop"
                         fxHide.gt-xs
                         fxShow>
              </lord-icon>


              <div class="ff-sc-5" ngClass.gt-xs="mat-body-1 f-600 p-t-15 m-b-3" ngClass.xs="mat-body-2 f-600 m-t-m5 m-b-0">
                등기변경 알림신청
              </div>
              <div ngClass.gt-xs="mat-body-1" ngClass.xs="mat-body-2">간편등록 &middot; 등본 열람</div>
            </div>
          </div>

          <div class="cursor w-100 brd-15" fxFlex.gt-xs="33" fxFlex.xs="48" id="useinfo"
               ngClass.gt-xs="p-b-20 p-t-40 m-t-m50 p-h-15 bg-white shadow-box-xs hover-gray-shadow"
               ngClass.xs="p-h-5 bg-gray-light p-t-8 p-b-11 m-b-15 shadow-box"
               routerLink="/info/my-house">
            <div class="text-center" fxLayout="column" fxLayoutAlign="center center">
              <lord-icon
                colors="primary:#0fa564,secondary:#0fa564,tertiary:#f6f7f7"
                fxHide
                fxShow.gt-xs
                src="https://cdn.lordicon.com/kwifjvdd.json"
                style="width:90px;height:90px"
                target="#useinfo"
                trigger="hover">
              </lord-icon>
<!--              <lord-icon-->
<!--                colors="primary:#0fa564,secondary:#0fa564,tertiary:#f6f7f7"-->
<!--                fxHide.gt-xs-->
<!--                fxShow-->
<!--                src="https://cdn.lordicon.com/kwifjvdd.json"-->
<!--                style="width:70px;height:70px"-->
<!--                target="#useinfo"-->
<!--                trigger="hover">-->
<!--              </lord-icon>-->


              <lord-icon colors="primary:#333333,secondary:#333333"
                         delay="10000"
                         fxHide.gt-xs
                         fxShow
                         src="https://cdn.lordicon.com/dbkviqyk.json"
                         style="width:62px;height:62px;"
                         trigger="loop">
              </lord-icon>



              <div class="ff-sc-5" ngClass.gt-xs="mat-body-1 f-600 p-t-15 m-b-3" ngClass.xs="mat-body-2 f-600 m-t-5 m-b-0">
                이용 안내
              </div>
              <div ngClass.gt-xs="mat-body-1" ngClass.xs="mat-body-2">등기변경알림 &middot; 등본 열람
              </div>
            </div>
          </div>

          <div [queryParams]="{type: 'INQUIRE'}" class="cursor w-100 brd-15" fxFlex.gt-xs="33" fxFlex.xs="48" fxHide.xs
               fxShow.gt-xs id="qna"
               ngClass.gt-xs="p-b-20 p-t-40 m-t-m50 p-h-15 bg-white shadow-box-xs hover-gray-shadow"
               ngClass.xs="p-h-5 bg-white p-t-8 p-b-11 m-b-10"
               routerLink="/inquire">
            <div class="text-center" fxLayout="column" fxLayoutAlign="center center">
              <lord-icon
                colors="primary:#f6f7f7,secondary:#0fa564,tertiary:#0fa564"
                fxHide
                fxShow.gt-xs
                src="https://cdn.lordicon.com/rfbqeber.json"
                style="width:90px;height:90px"
                target="#qna"
                trigger="hover">
              </lord-icon>
              <lord-icon
                colors="primary:#f6f7f7,secondary:#0fa564,tertiary:#0fa564"
                fxHide.gt-xs
                fxShow
                src="https://cdn.lordicon.com/rfbqeber.json"
                style="width:70px;height:70px"
                target="#qna"
                trigger="hover">
              </lord-icon>

              <div class="ff-sc-5" ngClass.gt-xs="mat-body-1 f-600 p-t-15 m-b-3" ngClass.xs="mat-body-2 f-600 m-b-0">
                서비스 문의
              </div>
              <div ngClass.gt-xs="mat-body-1" ngClass.xs="mat-body-2 c-gray-deep">이용문의 &middot; 기업회원문의
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>

<div ngClass.gt-xs="p-b-10" ngClass.xs="bg-gray-light">
  <section class="banner" ngClass.xs="p-t-2">
    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="20px" fxLayoutGap.xs="15px"
         ngClass.gt-xs="p-v-40 m-t-m50" ngClass.xs="p-h-15">
      <div fxFlex fxFlex.gt-xs="40" ngClass.xs="m-t-40">
        <div [queryParams]="{type: 'EVENT'}" class="p-b-10 p-t-7 cursor brd-15 hover-dark"
             id="event" ngClass.gt-xs="p-l-30 p-r-10 bg-white-blur shadow-box-xs hover-gray-shadow"
             ngClass.xs="p-l-15 p-r-5 m-b-10 p-b-20" routerLink="/info/events">
          <div class="text-right m-t-m50" ngClass.xs="p-r-10">
            <lord-icon
              colors="primary:#212121,secondary:#FFD54F"
              delay="3000"
              src="https://cdn.lordicon.com/ttrspzxk.json"
              style="width:90px;height:90px"
              trigger="loop">
            </lord-icon>
          </div>
          <div class="p-b-10" fxLayout="column">
            <div ngClass.gt-xs="mat-subtitle-2 m-b-10 ff-sc-6" ngClass.xs="mat-subtitle-2 f-600 m-b-10 ff-sc-5">
              부동산 등기변경알림 &middot; 등본열람 무료쿠폰
            </div>
            <div ngClass.gt-xs="p-b-20">
              <div ngClass.gt-xs="mat-body-1 f-600 m-b-2" ngClass.xs="mat-body-2 m-b-5">
                등기변경알림과 등기부등본 열람을 무료로 이용해보세요!
              </div>
              <div class="mat-body-2 m-b-3">
                회원가입 시 자동발급됩니다.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex fxFlex.gt-xs="60" fxHide fxShow.gt-xs>
        <div class="cursor w-100 shadow-box-xs brd-15" fxLayout.gt-xs="row" fxLayout.xs="row wrap"
             fxLayoutGap.gt-xs="12px"
             fxLayoutGap.xs="7px">
          <div class="cursor w-100" fxFlex.gt-xs="33" fxFlex.xs="48" id="coupon" ngClass.gt-xs="p-v-17 p-h-15"
               ngClass.xs="p-12 m-b-7" routerLink="/coupon">
            <div class="text-center" fxLayout="column" fxLayoutAlign="center center">
              <lord-icon colors="primary:#212121,secondary:#f6f7f7"
                         src="https://cdn.lordicon.com/smddbjcd.json"
                         style="width:75px;height:75px"
                         target="#coupon"
                         trigger="hover">
              </lord-icon>

              <div class="ff-sc-5" ngClass.gt-xs="mat-body-1 f-600 m-b-3" ngClass.xs="mat-body-2 f-600 p-t-5 m-b-0">
                쿠폰 구입
              </div>
              <div ngClass.gt-xs="mat-body-1 c-gray-deep" ngClass.xs="mat-body-1 c-gray-deep">등기변경 &middot; 등본 열람
              </div>
            </div>
          </div>

          <div [queryParams]="{type: 'ERROR'}" class="cursor w-100 m-t-2" fxFlex.gt-xs="33" fxFlex.xs="48"
               id="error" ngClass.gt-xs="p-v-17 p-h-15" ngClass.xs="p-12 m-b-7" routerLink="/inquire">
            <div class="text-center" fxLayout="column" fxLayoutAlign="center center">
              <lord-icon colors="primary:#212121,secondary:#f6f7f7"
                         src="https://cdn.lordicon.com/rgiefjyl.json"
                         style="width:75px;height:75px"
                         target="#error"
                         trigger="hover">
              </lord-icon>

              <div class="ff-sc-5" ngClass.gt-xs="mat-body-1 f-600 m-b-3" ngClass.xs="mat-body-1 f-600 m-b-0 p-t-5">오류
                신고
              </div>
              <div ngClass.gt-xs="mat-body-1 c-gray-deep" ngClass.xs="mat-body-1 c-gray-deep">서비스 &middot; 정보 오류</div>
            </div>
          </div>

          <div [queryParams]="{type: 'NOTICE'}" class="cursor w-100" fxFlex.gt-xs="33" fxFlex.xs="48" fxHide
               fxShow.gt-xs id="notice" ngClass.gt-xs="p-v-17 p-h-15"
               ngClass.xs="p-12 m-b-7" routerLink="/info/notice">
            <div class="text-center" fxLayout="column" fxLayoutAlign="center center">
              <lord-icon colors="primary:#212121,secondary:#212121,tertiary:#f6f7f7"
                         src="https://cdn.lordicon.com/ckatldkn.json"
                         style="width:75px;height:75px"
                         target="#notice"
                         trigger="hover">
              </lord-icon>

              <div class="ff-sc-5" ngClass.gt-xs="mat-body-1 f-600 m-b-3" ngClass.xs="mat-body-1 f-600 m-b-0 p-t-5">
                공지사항
              </div>
              <div ngClass.gt-xs="mat-body-1 c-gray-deep" ngClass.xs="mat-body-1 c-gray-deep">업데이트 &middot; 공지</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="bg-gray-light">
  <section class="banner" fxLayout="column" fxLayout.gt-xs="row"
           fxLayoutAlign.gt-xs="space-around center" fxLayoutGap="30px" fxLayoutGap.gt-xs="40px"
           ngClass.gt-xs="m-b-15">

    <div ngClass.gt-xs="p-v-20 p-l-20" ngClass.xs="p-h-15">
      <div fxFlex.gt-xs="51">
        <div ngClass.gt-xs="p-t-40" ngClass.xs="p-h-10 p-t-30 p-b-40">
          <div ngClass.gt-xs="mat-subtitle-1 f-600 ff-sc-6 p-t-20 m-b-7"
               ngClass.xs="mat-subtitle-2 f-600 ff-sc-6 p-t-10 m-b-5">
            온투업 부동산 투자상품의
          </div>
          <div class="ff-sc-5 lh-1-7" ngClass.gt-xs="mat-headline-6 m-b-15" ngClass.xs="mat-subtitle-1 f-600 ff-sc-6">
            담보정보를 무료로 확인하세요!
          </div>
          <div ngClass.gt-xs="ff-sc-4 f-600 mat-body-1 m-b-5" ngClass.xs="ff-sc-4 f-600 mat-body-1 m-b-5">
            투자자는 알림신청만으로 투자부동산의 담보현황을 확인할 수 있습니다.
          </div>

          <div class="p-t-10" fxHide.gt-xs fxShow ngClass.xs="p-b-50" routerLink="/info/open-house">
            <div class="cursor" fxLayout="row wrap">
              <div *ngFor="let logo of partnerLogos" fxFlex="25" fxLayoutAlign="start center">
                <div>
                  <img [src]="logo" alt="image" class="img-size-95p">
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" ngClass.gt-xs="p-t-40 w-70">
            <button class="bg-primary hover-primary p-v-25 f-600 l-h-0" fxFlex mat-raised-button
                    ngClass.gt-xs="p-h-40"
                    ngClass.xs="p-h-0" routerLink="/house/open">
              오픈부동산 보기
            </button>

            <button class="bg-gray hover-dark p-v-25 f-600" ngClass.gt-xs="p-h-40" ngClass.xs="p-h-0" fxFlex
                    mat-raised-button
                    routerLink="/info/open-house">
              서비스 소개
            </button>
          </div>
        </div>
      </div>

      <div fxFlex.gt-xs="44" fxHide fxShow.gt-xs>
        <div ngClass.gt-xs="p-v-20 text-center">
          <div ngClass.xs="p-b-50" routerLink="/info/open-house">
            <div class="cursor" fxLayout="row wrap">
              <div *ngFor="let logo of partnerLogos" fxFlex="20" fxLayoutAlign="start center">
                <div class="p-2">
                  <img [src]="logo" alt="image" class="img-size-100p">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="showStoreLink">
  <section class="narrow-section banner-xs">
    <div ngClass.xs="p-20">
      <div class="p-b-20" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="40px">
        <img (click)="openPlayStore()" alt="Play Store Link" class="cursor img-size-50"
             src="assets/img/store/GooglePlay.png">
        <img (click)="openAppStore()" alt="AppStore Link" class="cursor img-size-50"
             src="assets/img/store/AppStore.png">
      </div>
      <div class="mat-body-2 text-center">
        체크팀 앱을 다운받으시면 등기변경 안내를 앱(푸시) 알림으로도 확인할 수 있습니다.
      </div>
    </div>
  </section>
</div>

<app-footer></app-footer>
