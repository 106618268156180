import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './pages/main/main.component';
import {UserGuard} from './_guard/user.guard';
import {RefreshComponent} from './refresh/refresh.component';

const routes: Routes = [
  {path: '', component: MainComponent},
  {path: 'refresh', component: RefreshComponent},
  {path: 'join/sns', loadChildren: () => import('./pages/join/join-sns/join-sns.module').then(m => m.JoinSnsModule)},
  {path: 'join', loadChildren: () => import('./pages/join/join.module').then(m => m.JoinModule)},
  {path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)},
  {
    path: 'coupon',
    loadChildren: () => import('./pages/coupon/coupon.module').then(m => m.CouponModule),
    canActivate: [UserGuard]
  },
  {
    path: 'inquire',
    loadChildren: () => import('./pages/inquire/inquire.module').then(m => m.InquireModule),
    canActivate: [UserGuard]
  },
  {
    path: 'house/register',
    loadChildren: () => import('./pages/house/house-register/house-register.module').then(m => m.HouseRegisterModule),
    canActivate: [UserGuard]
  },
  {
    path: 'house/batch',
    loadChildren: () => import('./pages/house/house-excel-register/house-excel-register.module').then(m => m.HouseExcelRegisterModule),
    canActivate: [UserGuard]
  },
  {
    path: 'house/detail',
    loadChildren: () => import('./pages/house/house-detail/house-detail.module').then(m => m.HouseDetailModule),
    canActivate: [UserGuard]
  },
  {
    path: 'house/extends',
    loadChildren: () => import('./pages/house/house-extends/house-extends.module').then(m => m.HouseExtendsModule),
    canActivate: [UserGuard]
  },
  {
    path: 'house/open',
    loadChildren: () => import('./pages/house/house-open-list/house-open-list.module').then(m => m.HouseOpenListModule)
  },
  {
    path: 'house',
    loadChildren: () => import('./pages/house/house-dashboard/house-dashboard.module').then(m => m.HouseDashboardModule),
    canActivate: [UserGuard]
  },
  {
    path: 'analysis',
    loadChildren: () => import('./analysis/pages/analysis.module').then(m => m.AnalysisModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate: [UserGuard]
  },
  {
    path: 'info/my-house',
    loadChildren: () => import('./pages/info/my-house/my-house.module').then(m => m.MyHouseModule)
  },
  {
    path: 'info/open-house',
    loadChildren: () => import('./pages/info/open-house/open-house.module').then(m => m.OpenHouseModule)
  },
  {path: 'info/events', loadChildren: () => import('./pages/info/notice/notice.module').then(m => m.NoticeModule)},
  {path: 'info/notice', loadChildren: () => import('./pages/info/notice/notice.module').then(m => m.NoticeModule)},
  {path: 'info/update', loadChildren: () => import('./pages/info/notice/notice.module').then(m => m.NoticeModule)},
  {path: 'info/company', loadChildren: () => import('./pages/info/company/company.module').then(m => m.CompanyModule)},
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationModule),
    canActivate: [UserGuard]
  },
  {path: 'terms', loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule)},
  {path: 'purchase', loadChildren: () => import('./pages/toss/toss.module').then(m => m.TossModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
