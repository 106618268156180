import {Component, Inject} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Router} from '@angular/router';
import {UserService} from '../../../domain/user/service/user.service';
import {SessionStorageService} from '../../../_service/session-storage.service';
import {NativeService} from '../../../_service/native.service';

@Component({
  selector: 'app-mobile-footer-sheet',
  templateUrl: './mobile-footer-sheet.component.html',
  styleUrls: ['./mobile-footer-sheet.component.scss']
})
export class MobileFooterSheetComponent {
  links: any[] = [];

  constructor(private log: NGXLogger,
              @Inject(MAT_BOTTOM_SHEET_DATA) private data: any,
              private nativeService: NativeService,
              private ref: MatBottomSheetRef,
              private router: Router,
              private userService: UserService,
              private sessionStorage: SessionStorageService) {
    this.links = this.data.links;
  }

  clickLink(link: any) {
    if (link.logout) {
      this.userService.logout();
      this.sessionStorage.clear();
      this.nativeService.forgetMe();
    } else {
      this.router.navigate([link.path], {queryParams: link.queryParams})
      this.ref.dismiss(link.type)
    }
    this.ref.dismiss();
  }
}
