import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InfoDialogComponent} from './info-dialog.component';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class InfoDialogService {

  constructor(private log: NGXLogger, private matDialog: MatDialog) {
  }

  public open(title: string, content: string): MatDialogRef<any> {
    return this.matDialog.open(InfoDialogComponent, {data: {title, content}})
  }

  public openWithButtons(title: string, content: string, confirmButtonText: string, cancelButtonText: string): Observable<any> {
    return this.matDialog.open(InfoDialogComponent, {data: {title, content, confirmButtonText, cancelButtonText}})
      .afterClosed();
  }

  public openWithCancel(title: string, content: string): Observable<any> {
    return this.openWithButtons(title, content, '확 인', '취 소')
  }
}
