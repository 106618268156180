import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

@Injectable({providedIn: 'root'})
export class SessionStorageService {

  constructor(private log: NGXLogger) {
  }

  get(key: string): any {
    const sessionValue = sessionStorage.getItem(key);
    this.log.debug('세션 스토리지에서 꺼내기', sessionValue);
    if (sessionValue) {
      return JSON.parse(sessionValue);
    }
  }

  put(key: string, value: any) {
    this.log.debug('세션 스토리지 저장', key)
    sessionStorage.setItem(key, JSON.stringify(value))
  }

  clear() {
    Object.keys(sessionStorage).forEach(key => {
      if (key !== 'currentUser' && key !== 'previous') {
        sessionStorage.removeItem(key);
      }
    });
  }
}
