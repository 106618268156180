import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RestResponse} from './rest-response';

@Injectable()
export class RestClientService {
  private readonly API_HOST: string;

  constructor(@Inject('API_HOST') private apiHost: string, private httpClient: HttpClient) {
    this.API_HOST = this.apiHost;
  }

  public getBaseUrl() {
    return this.API_HOST;
  }

  public get(path: string, params?: any): Observable<any> {
    return this.httpClient.get<any>(this.createRequestUrl(path), {params});
  }

  public getFile(path: string): Observable<Blob> {
    return this.httpClient.get(this.createRequestUrl(path), {responseType: 'blob'});
  }

  public put(path: string, params?: any): Observable<RestResponse> {
    return this.httpClient.put<any>(this.createRequestUrl(path), params);
  }

  public patch(path: string, params?: any): Observable<RestResponse> {
    return this.httpClient.patch<any>(this.createRequestUrl(path), params);
  }

  public post(path: string, params?: any): Observable<RestResponse> {
    return this.httpClient.post<any>(this.createRequestUrl(path), params);
  }

  public delete(path: string, params?: any): Observable<RestResponse> {
    return this.httpClient.delete<any>(this.createRequestUrl(path), {params});
  }

  public postForm(path: string, params?: any): Observable<any> {
    const formData = new FormData();
    this.createFormData(formData, params);
    return this.httpClient.post<any>(this.createRequestUrl(path), formData);
  }

  public patchForm(path: string, params?: any): Observable<RestResponse> {
    const formData = new FormData();
    this.createFormData(formData, params);
    return this.httpClient.patch<any>(this.createRequestUrl(path), formData);
  }

  public putForm(path: string, params?: any): Observable<RestResponse> {
    const formData = new FormData();
    this.createFormData(formData, params);
    return this.httpClient.put<any>(this.createRequestUrl(path), formData);
  }

  private createRequestUrl(path: string): string {
    return path.includes('http') ? path : `${this.API_HOST}${path}`;
  }

  private createFormData(formData: any, data: any, parentKey?: any): any {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      Object.keys(data).forEach(key => {
        if (parentKey && parentKey.includes('[')) {
          this.createFormData(formData, data[key], parentKey ? `${parentKey}.${key}` : key);
        } else {
          this.createFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        }
      });
    } else {
      if (data) {
        formData.append(parentKey, data);
      }
    }
  }
}
