import {Injectable} from '@angular/core';
import {RestClientService} from '../../../../projects/shared/src/lib/rest-client.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class NoticeService {

  constructor(private restClient: RestClientService) {
  }

  public getNotice(request: any): Observable<any> {
    return this.restClient.get('/notice', request)
      .pipe(map(response => {
        if (response.success) {
          return response.data;
        }
      }))
  }

  public getMainPageNotice(): Observable<any> {
    return this.restClient.get('/notice/main')
      .pipe(map(response => {
        if (response.success) {
          return response.data;
        }
      }))
  }
}
