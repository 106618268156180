import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {RestClientService} from '../../../../projects/shared/src/lib/rest-client.service';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class NotificationService {
  private change = new Subject<number>();

  constructor(private log: NGXLogger, private restClient: RestClientService) {
  }

  public subscribe(): Subject<number> {
    return this.change;
  }

  countNotifications(): Observable<any> {
    return this.restClient.get('/users/notifications/count')
      .pipe(map(response => {
        if (response.success) {
          this.change.next(response.data)
          return response.data;
        }

        return 0;
      }));
  }

  getNotifications(request: any): Observable<any> {
    return this.restClient.get('/users/notifications', request)
      .pipe(map(response => {
        return response.data;
      }));
  }

  deleteAllNotifications(): Observable<any> {
    return this.restClient.delete('/users/notifications')
      .pipe(map(response => {
        return response.success;
      }));
  }

  deleteNotification(id: number): Observable<any> {
    return this.restClient.delete(`/users/notifications/${id}`)
      .pipe(map(response => {
        return response.success;
      }));
  }
}
