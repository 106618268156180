import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {catchError, finalize, map} from 'rxjs/operators';
import {ToastService} from '../../../projects/shared/src/lib/toast.service';
import {LoginDialogService} from '../shared/dialog/login-dialog/login-dialog.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserService} from "../domain/user/service/user.service";

@Injectable()
export class DefaultHttpInterceptor implements HttpInterceptor {

  constructor(private log: NGXLogger,
              private spinner: NgxSpinnerService,
              private toast: ToastService,
              private loginDialog: LoginDialogService,
              private userService: UserService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.spinner.show();

    return next.handle(request.clone({withCredentials: true}))
      .pipe(map((response: any) => {
          if (response.body) {
            this.log.debug('http Response: ', response.body);
          }

          if (response.body instanceof Blob) {
            return response;
          }

          if (response.body && !response.body.success) {
            this.toast.show(response.body.message);
          }

          return response;
        }),
        catchError(response => {
          this.log.error(response.status, response.error);

          if (response.status === 401) {
            this.log.debug('Unauthorized')
            this.userService.clearSession();
          } else if (response.status === 403) {
            this.toast.show('로그인이 필요한 서비스 입니다.');
            this.loginDialog.openLoginDialog();
          }

          if (response.error.errors) {
            this.toast.show(response.error.errors[0].defaultMessage)
          } else if (response.error.message !== undefined) {
            this.toast.show(response.error.message);
          } else {
            this.toast.show(response.error);
          }
          return throwError(response);
        }), finalize(() => {
          this.spinner.hide();
        }));
  }
}
