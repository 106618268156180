import {Component, HostListener} from '@angular/core';
import {ViewportScroller} from "@angular/common";

@Component({
  selector: 'app-scroll',
  templateUrl: './scroll.component.html',
  styleUrls: ['./scroll.component.scss']
})
export class ScrollComponent {
  show = false;

  constructor(private scroll: ViewportScroller) {
  }

  @HostListener('window:scroll', ['$event.target'])
  onWindowScroll(document: any) {
    let scroll = document.scrollingElement.scrollTop;
    this.show = scroll > window.visualViewport!.height;
  }

  scrollTop() {
    this.scroll.scrollToPosition([0, 1])
  }
}
