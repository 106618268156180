import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';

@Injectable()
export class Paginator extends MatPaginatorIntl {

  constructor() {
    super();
    this.itemsPerPageLabel = '페이지 당 : ';
    this.nextPageLabel = '다음';
    this.previousPageLabel = '이전';
    this.firstPageLabel = '첫 페이지';
    this.lastPageLabel = '마지막 페이지';
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      const lastPage = Math.ceil(length / pageSize);
      return `${page + 1} / ${lastPage === 0 ? 1 : lastPage}`;
    };
  }
}
