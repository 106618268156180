import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class ToastService {

  constructor(private snackbar: MatSnackBar) {
  }

  public show(content: string): void {
    if (content.length > 0) {
      this.snackbar.open(content);
    }
  }
}
